// related to bootstrap-5
.top-0 {
  top: 0;
}
.start-50 {
  left: 50%;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.translate-middle {
    transform: translate(-50%);
}
.skip-navigation-btn {
    transform: translateY(-100%);
    transition: all .5s ease-in;
}
.skip-navigation-btn:focus {
    transform: translateY(0);
}