.sidenav.burger-icon,
.dropdown.burger-icon {
  overflow: hidden;
  display: grid;
  align-content: space-around;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0.2rem;
  border: none;
  background-color: transparent;
}

.sidenav .bar,
.dropdown .bar {
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background-color: #a3a4a6;
  transition: all 0.2s ease;
}

.burger-icon:focus {
  outline: none;
}

.burger-icon:hover, .burger-icon:focus {
  .bar {
    background-color: #4e5155;
  }
}

.layout-collapsed .sidenav.burger-icon .top-bar {
  transform: scaleX(1.1) translateX(-0.2rem);
}

.layout-collapsed .sidenav.burger-icon .middle-bar {
  transform: scaleX(1.1) translateX(-0.45rem);
}

.layout-collapsed .sidenav.burger-icon .bottom-bar {
  transform: scaleX(1.1) translateX(-0.7rem);
}

.dropdown.burger-icon:not(.collapsed) .top-bar {
  transform: translate(0px, 6.5px) rotate(45deg);
}

.dropdown.burger-icon:not(.collapsed) .middle-bar {
  transform: translateX(30px);
}

.dropdown.burger-icon:not(.collapsed) .bottom-bar {
  transform: translate(0px, -6.5px) rotate(-45deg);
}

.dropdown.burger-icon {
  transform: none;
}

@media (max-width: 991px) {
  .layout-collapsed .sidenav.burger-icon .top-bar,
  .layout-collapsed .sidenav.burger-icon .middle-bar,
  .layout-collapsed .sidenav.burger-icon .bottom-bar {
    transform: none;
  }
}
