$adjust-position: 1px;
$font-faded: #849095;

.rdrDateDisplayItem {
  input {
    border: 0px;
    color: $font-faded;
  }
}
.rdrDateDisplayItemActive {
  border: 1px solid $primary-color;

  input {
    color: $primary-color;
  }
}

.rdrDayToday .rdrDayNumber {
  border: 1px solid $primary-color;
  color: $primary-color;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  background: $primary-color;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  border: 0px solid $primary-color;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  content: "";
  border: 1px solid $primary-color;
  border-radius: 1.333em;
  top: -$adjust-position;
  bottom: -$adjust-position;
  position: absolute;
  background: transparent;
  left: 0px;
  right: 0px;
  border: none;
}

.rdrStaticRange {
  &:hover,
  &:focus {
    .rdrStaticRangeLabel {
      color: black;
    }
  }
}

.rdrStaticRangeSelected {
  span {
    color: $primary-color;
  }
}

.rdrInputRangeInput {
  border: solid 1px $text-muted;
}