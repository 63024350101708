.business-panel {
  height: 66px;
}
.business-image {
  display: grid;
  place-items: center;
  width: 3rem;
  height: 3rem;
  border: 2px solid white;
  color: #444444;
  background-color: gainsboro;
  border-radius: 50%;
}
.business-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 160px;
  font-size: medium;
  font-weight: bold;
}
.business-rolename {
  font-size: 12px;
  letter-spacing: 0.5px;
}