@-webkit-keyframes RouterLoaderAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes RouterLoaderAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes RouterLoaderAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Container {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(#fff, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: RouterLoaderAnimation 0.2s;
  animation-fill-mode: forwards;
}
:global(.dark-style) .Container {
  background: rgba(#25282e, 0.25);
}

.Spinner {
  display: block;
}

.SpinnerEl {
  border-width: 2px !important;
  width: 3rem !important;
  height: 3rem !important;
}

:global(.app-loading) .Container {
  display: none;
}
