.card {
  height: 100%;
  font-size: 1.25rem;
}

.body {
  display: grid;
  place-items: center;
}

.container {
  display: grid;
  place-items: center;
}

.status {
  font-size: 10rem;
  font-weight: 400;
  margin: 0;
  line-height: 1;
}

.message {
  font-weight: 300;
  font-size: 1.5rem;
  margin: 0;
  line-height: 1;
}
