$wbc-primary: #DA1710;
$wbc-hero: #1F1C4F;
$wbc-neutral: #2A2E42;
$wbc-heading: #1F1C4F;
$wbc-text: #181B25;
$wbc-muted: #595767;
$wbc-border: #DEDEE1;
$wbc-background: #F3F4F6;
$wbc-light: #F9F9FB;

$wbc-bright-purple: #991AD6;
$wbc-bright-pink: #FF3DDB;
$wbc-dark-red: #990000;
$wbc-light-grey: #E8E8ED;
$wbc-light-purple: #E0BAF2;
$wbc-light-pink: #FFD9F7;

$wbc-info: #0074C4;
$wbc-success: #008000;
$wbc-warning: #C53B00;
$wbc-danger: #C40000;
$wbc-system: #FF0;

$primary-color: $wbc-hero;
$body-bg: $wbc-background;


$white:        #fff;
$black:        $wbc-text;
$gray-25:      rgba($black, .015);
$gray-50:      rgba($black, .03);
$gray-100:     rgba($black, .06);
$gray-200:     rgba($black, .1);
$gray-300:     rgba($black, .2);
$gray-400:     rgba($black, .3);
$gray-500:     rgba($black, .4);
$gray-600:     rgba($black, .5);
$gray-700:     rgba($black, .6);
$gray-800:     rgba($black, .8);
$gray-900:     rgba($black, .9);

$grays: (
  "25": $gray-25,
  "50": $gray-50
) !default;

$blue:         #1e70cd;
$indigo:       #6610f2;
$purple:       #6f42c1;
$pink:         #e83e8c;
$red:          #d9534f;
$orange:       #FEB744;
$yellow:       #FFD950;
$green:        #02BC77;
$teal:         #20c997;
$cyan:         #28c3d7;

$theme-colors: (
  "primary":   $wbc-hero,
  "secondary": $wbc-muted,
  "success":   $wbc-success,
  "info":      $wbc-info,
  "warning":   $wbc-warning,
  "danger":    $wbc-danger,
  "dark":      $wbc-neutral,
) !default;

$link-color: $wbc-primary;
$link-decoration: underline;
.navbar-brand,
.sidenav a {
  text-decoration: none;
}

.bg-navbar-theme .navbar-nav .nav-link {
  color: $wbc-text !important;
}

.navbar-nav .dropdown .dropdown-item i {
  color: $wbc-text !important;
}
.table-primary a {
  color: $wbc-text !important;
  text-decoration: none;
}


// h2-6: #181b25;
// h1 1f1c4f;

$overdueColour: $wbc-danger;
$unpaidColour: $wbc-info;
$acceptedColour: $wbc-bright-purple;
$quotedColour: $wbc-text;
$draftColour: $wbc-muted;
$paidColour: $wbc-success;
$refundColour: $wbc-primary;
// $voidColour: #7f6cc6;
$incompleteColour: $wbc-warning;
$timelineOutline: $wbc-hero !default;

// $sidenav-bg-color: #da1710;
// $sidebar-gradient-start: #a10d07;
// $sidebar-gradient-end: #da1710;
// $sidenav-bg-color: #1f1c4f;
// $sidebar-gradient-start: #1f1c4f;
// $sidebar-gradient-end: #262163;
$sidenav-bg-color: #fff;
$sidebar-gradient-start: #fff;
$sidebar-gradient-end: #fff;

:root {
  --timeLineInverter: enabled; // this is intentionally an invalid valule to abuse var() processing
}

.bg-sidenav-theme,
.sidenav-inner nav > .sidenav-header,
.sidenav-link .sidenav-icon,
.bg-sidenav-theme .sidenav-link,
a.sidenav-item {
  color: $black !important;
}

@import "./base.scss";
@include appwork-button-variant('.btn-primary', $wbc-primary);
@include appwork-button-outline-variant('.btn-outline-primary', $wbc-primary);


.summary-sales-count,
.summary-net-sales,
.summary-net-sales-includes,
.summary-payment-breakdown {
  .card-header {
    color: $white !important;
    background-color: $wbc-neutral !important;
  }
  border-color: $wbc-neutral !important;
  color: $wbc-neutral !important;
}