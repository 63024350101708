.vw-search {
  padding: 1.5rem;
  padding-top: 0;
}

.table-shaded {
  background-color: #fafafa;
}

.text-faded {
  color: rgb(223, 223, 223);
}

.tHeader,
.vHeader {
  th {
    cursor: pointer;
    user-select: none;
  }
}

.vHeader {
  display: grid;
  grid-auto-flow: column;
  overflow-y: scroll;
}

.vContent {
  display: grid;
  grid-auto-flow: column;
  position: absolute;
  width: 100%;
  tr {
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.vList, .vHeader {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
